import * as ActionTypes from './ActionTypes';

export const Country = (state = { isLoading: true,
    errMess: null,
    country:[]}, action) => {
    switch (action.type) {
        case ActionTypes.ADD_COUNTRY:
            return {...state, isLoading: false, errMess: null, country: action.payload};

        case ActionTypes.COUNTRY_LOADING:
            return {...state, isLoading: true, errMess: null, country: []};

        case ActionTypes.COUNTRY_FAILED:
            return {...state, isLoading: false, errMess: action.payload};

        default:
            return state;
    }
};