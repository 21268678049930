
import React from 'react';
import { Link } from 'react-router-dom';

function Footer(props) {
    return(
    <div className="footer text-info">
        <div className="container">
            <div className="row justify-content-center">             
                <div className="col-4 offset-1 col-sm-2">
                    <ul className="list-unstyled">
                    <li><Link to='/aboutus'>Privacy Policy</Link></li>
                        <li><Link to='/aboutus'>Cookies</Link></li>
                        <li><Link to='/aboutus'>Terms</Link></li>
                        <li> <a href="mailto:info@treatus.eu">
                         Contact Us </a><i className="fa fa-envelope fa-lg"></i></li>
                    </ul>
                </div>
                <div className="col-7 col-sm-5 text-info" style={{fontSize: 14}}>
                    <span style={{ fontWeight: 'bold' }} > © Copyright 2020 covid.TreatUs.eu</span>
                    <div>
                    The use of images and articles is only permitted if the website logo is maintained or a link to the source is provided. 
                    We provide data and graphical information "as is" without obligation to ensure its accuracy
                    </div>
                </div>
   
            </div>
            <div className="row justify-content-center">             
                <div className="col-auto">
                    <p></p><p> </p>
                </div>
            </div>
        </div>
    </div>
    )
}

export default Footer;
