export const FEEDBACKS_FAILED = 'FEEDBACKS_FAILED';
export const ADD_PROMOS = 'ADD_PROMOS'; //
export const PROMOS_LOADING = 'PROMOS_LOADING'; //
export const PROMOS_FAILED = 'PROMOS_FAILED'; //
export const ADD_ARTICLE = 'ADD_ARTICLE'; //
export const ARTICLE_LOADING = 'ARTICLE_LOADING'; //
export const ARTICLE_FAILED = 'ARTICLE_FAILED'; //
export const ADD_COUNTRY = 'ADD_COUNTRY'; //
export const COUNTRY_LOADING = 'COUNTRY_LOADING'; //
export const COUNTRY_FAILED = 'COUNTRY_FAILED'; //
