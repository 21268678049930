import React from 'react';
import { Card, CardImg, CardText, CardBody, CardTitle, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import {Nav, NavItem, 
    Button, Modal, ModalHeader, ModalBody, Jumbotron, Container, Label, Row, Col, Form, FormGroup} from 'reactstrap';
import { Link } from 'react-router-dom';
import { Control, LocalForm, Errors } from 'react-redux-form';
import { Loading } from './LoadingComponent';
import { pngUrl } from '../shared/baseUrl';
import { FadeTransform, Fade, Stagger } from 'react-animation-components';


class CountryForm extends React.Component {
  
 constructor(props) {
    super(props);


    this.toggleNav = this.toggleNav.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
        isNavOpen: false,
        isModalOpen: false,
        value: 'world'
    };

  }


  onButtonClick() {
    console.log(this.myRef.current.value);
    alert (this.myRef.current.value+"fffffffffffff");
}

  handleChange(event) {
    this.setState({value: event.target.value});
  }

    handleSubmit(values) {
        alert('Your favorite flavor is: ' + this.state.value);
       // event.preventDefault();
      //this.toggleModal();
      this.props.filterCountry(values.rating);
    alert('Current State is: ' + JSON.stringify(values));     // <XXXX 
      //console.log('Current State is: ' + JSON.stringify(values));
      //alert('Current State is: ' + JSON.stringify(values));
      //event.preventDefault();
  }

  toggleNav() {
    this.setState({
      isNavOpen: !this.state.isNavOpen
    });
  }

  toggleModal() {
    this.setState({
      isModalOpen: !this.state.isModalOpen
    });
  }
  




render(){
    return(
<div>
<Nav className="ml-auto" navbar>
                                <NavItem>
                                    <Button outline onClick={this.toggleModal} className="list"><span className="fa fa-globe fa-sm list"></span> {this.props.data.code3} Graphics</Button>
                                </NavItem>
                </Nav>

<Modal isOpen={this.state.isModalOpen} toggle={this.toggleModal}>
            <ModalHeader toggle={this.toggleModal}> Dynamics of LOVID-19 in <strong> {this.props.data.country} </strong>
            
            </ModalHeader>
            <ModalBody>

            <div>
                <Card>
                    <CardTitle style={{fontSize: 16}}>{this.props.data.cad} new cases of COVID-19 
                    (cumulative number for 14 days per 100 000 was {this.props.data.c14}) and {this.props.data.dad} 
                    deaths from COVID-19 (cumulative number for 14 days per 100 000 was {this.props.data.d14}) 
                    were reported on {this.props.data.date} in {this.props.data.country}.

                    </CardTitle>
                    <CardImg src={pngUrl + this.props.data.image} alt={this.props.data.image} />
                    <CardBody>
                    <div className="row align-items-start">
                     <div className="col-12 col-md m-1">
                    </div>
                    </div>
                    </CardBody>
                </Card>
                </div>


            </ModalBody>
        </Modal>
</div>
    );
}
}

  function RenderCountry({country, filterCountry}) {
    if(country != null) {
      return (<div><h2 className="list">
          <Row> 
          <Col> Country</Col>
          <Col className='d-none d-md-block gr'> Cases comulative no. of 14 days/100 000</Col>
          <Col className='d-none d-md-block red'> Deaths comulative no. of 14 days/1 000 000</Col>
          <Col className='d-none d-md-block gr'> Daily cases</Col>
          <Col className='d-none d-md-block red'> Daily deaths</Col>
          <Col> Dinamics</Col>
          <Col> </Col>
         
          </Row>       
          </h2><ul className="list-unstyled">
                          {country.map((country) => {
                            return (
                                <Fade in>
                                <li key={country.id}>
                                <p><Row className={country.continent}><Col>{country.country}</Col>
                                <Col className='d-none d-md-block gr'>{country.c14}</Col>
                                <Col className='d-none d-md-block red'>{country.d14}</Col>   
                                <Col className='d-none d-md-block gr'>{country.cdx}</Col>   
                                <Col className='d-none d-md-block red'>{country.ddx}</Col> 
                                <Col className='d-none d-md-block'>{country.Rx}</Col> 
                                <Col><CountryForm data={country} />
                                </Col></Row> </p>
                                </li>
                                </Fade>
                            );
                        })}
                      
        </ul>
        

        </div>);
  }

    else {
      return (
      <div>HIIIII</div>
      );
    }
  }
/////////////////////

const genres = [
    { id: 1, genre: "Action" },
    { id: 2, genre: "Horror" },
    { id: 3, genre: "Comedy" }
   ];

//////////////////////
/*

                    <Row className="form-group">
                                <Col md={{size: 10}}>
                                <Form.Control as="select" custom ref={}>
                                        <option value="world">Select continent</option>
                                        <option value="Europe">Europe</option>
                                        <option value="Africa">Africa</option>
                                        <option value="America">America</option>
                                        <option value="Asia">Asia</option>
                                        <option value="Oceania">Oceania</option>
                                    </Form.Control>
                                    <button onClick={this.onButtonClick}>Get color</button>
                                </Col>
                            </Row>

*/



  const  ListDetail = (props) => {
    if (props.isLoading) {
        return(
            <div className="container">
                <div className="row">            
                    <Loading />
                </div>
            </div>
        );
    }
    else if (props.errMess) {
        return(
            <div className="container">
                <div className="row">            
                    <h4>{props.errMess}</h4>
                </div>
            </div>
        );
    }
    //<LocalForm onChange={(values) => this.handleSubmit(values)}>
    else if (props.country != null) 
  return (
          <div className="container">
    <div className="row">

    <div>
        <Stagger in>
        <div className="container">
            <div className="row align-items-start">
                <div className="col-12 col-md m-1">
                <RenderCard item={props.article} 
                item2={props.article2} 
                isLoading={props.articleLoading} 
                errMess={props.articleErrMess}  />
                </div>
                </div>
                <Jumbotron>
                    <div className="container">
                        <div className="header">
                            <div className="col-12 col-sm-12 justify-content-center">
                               <Row> 
                                   <Col><h1 >Latest statistics from COVID-19 affected countries </h1></Col>

                                </Row>

                                </div>
                        </div>
                    </div>
                    </Jumbotron>
            </div>
            </Stagger>
            <div>
                    <ListDetail />
            </div>
        </div>

        <div lassName="col-12 col-md-5 m-1">
            <RenderCountry country={props.country} 
                    filterCountry={props.filterCountry}
                />



         
        </div>
     </div>
    </div>
);
/////////////

function RenderCard({item, item2, isLoading, errMess}) {
    
    if (isLoading) {
        return(
                <Loading />
        );
    }
    else if (errMess) {
      //  alert ("errMess:"+errMess);//
        return(
                <h4>{errMess}</h4>
        );
    }

    else if (item){
        //alert(item.world);
 
        return(
            <div className="col-12 d-none d-xl-block text-warning" style={{fontSize: 12}}>
              <Container>
                <Row>
                <Col>
                  <Card>
                    <CardImg src={pngUrl + item.image} alt={item.name} />
                    <CardText>{item.date + " number of cases for 14 days"}</CardText>
                  </Card>
                  </Col>

                <Col>
                  <Card>
                    <CardImg src={pngUrl + item2.image} alt={item2.name} />
                    <CardText>{item2.date + " number of cases for 14 days"}</CardText>
                  </Card>
                  </Col>

                </Row>
                </Container>
            </div>
         );
    }

    /*            <div className="col-12 d-none d-xl-block text-warning" style={{fontSize: 12}}>
              <Container>
                <Row>
                <Col>
                  <Card>
                    <CardImg src={baseUrl + item.image} alt={item.name} />
                    <CardText>{item.date + " number of cases for 14 days"}</CardText>
                  </Card>
                  </Col>
                  <Col>
                <Card>
                    <CardImg src={baseUrl + item.image} alt={item.name} />
                    <CardText>{item.date + " number of deaths for 14 days"}</CardText>
                </Card>
                </Col>
                <Col>
                  <Card>
                    <CardImg src={baseUrl + item.image} alt={item.name} />
                    <CardText>{item.date + " number of cases for 14 days"}</CardText>
                  </Card>
                  </Col>
                  <Col>
                <Card>
                    <CardImg src={baseUrl + item.image} alt={item.name} />
                    <CardText>{item.date + " number of deaths for 14 days"}</CardText>
                </Card>
                </Col>
                </Row>
                </Container>
            </div>*/
    else
    {
         return(
            <div>
                <p>server unavailable: {errMess}</p>
            </div>
        );
    }
}

//                 errMess={props.dishErrMess}  />  ZR PROPS masin component jei ka // buvo <RenderCard item={props.leader} />
function Country(props) {
    return(
       <div>
        <Stagger in>
        <div className="container">
            <div className="row align-items-start">
                <div className="col-12 col-md m-1">
                <RenderCard item={props.article} 
                item2={props.article2} 
                isLoading={props.articleLoading} 
                errMess={props.articleErrMess}  />
                </div>
                </div>
                <Jumbotron>
                    <div className="container">
                        <div className="header">
                            <div className="col-12 col-sm-12 justify-content-center">
                                <h1 >Latest statistics from COVID-19 affected countries</h1>
                                </div>
                        </div>
                    </div>
                    </Jumbotron>
            </div>
            </Stagger>
            <div>
                    <ListDetail />
            </div>
        </div>
    );
}


//////////////////
return (
    <div className="container">
        <div>
        {Country}
        </div>
        <div className="row">
            {ListDetail} 
        </div>
    </div>
);
}



 export default ListDetail;
