import * as ActionTypes from './ActionTypes';

export const Article = (state = { isLoading: true,
    errMess: null,
    article:[]}, action) => {
    switch (action.type) {
        case ActionTypes.ADD_ARTICLE:
            return {...state, isLoading: false, errMess: null, article: action.payload};

        case ActionTypes.ARTICLE_LOADING:
            return {...state, isLoading: true, errMess: null, article: []};

        case ActionTypes.ARTICLE_FAILED:
            return {...state, isLoading: false, errMess: action.payload};

        default:
            return state;
    }
};