import React from 'react';
import { Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Jumbotron} from 'reactstrap';
import { Loading } from './LoadingComponent';
import { pngUrl } from '../shared/baseUrl';
import { Stagger } from 'react-animation-components';

function RenderCard({item, isLoading, errMess}) {
    
    if (isLoading) {
        return(
                <Loading />
        );
    }
    else if (errMess) {
      //  alert ("errMess:"+errMess);//
        return(
                <h4>{errMess}</h4>
        );
    }

    else if (item){
        //alert(item.world);
 
        return(
            <div>
                <Card>
                    <CardTitle>{item.name} </CardTitle>
                    <CardImg src={pngUrl + item.image} alt={item.name} />
                    <CardBody>
                    {item.date ? <CardSubtitle>Data subset: {item.date } </CardSubtitle> : null }
                    <div className="row align-items-start">
                    <div>
                    {item.legend ? <CardImg src={pngUrl + item.legend} alt={item.legend} /> : null }
                    </div>
                    <div className="col-12 col-md m-1">
                    <CardText>{item.description}</CardText>
                    </div>
                    </div>
                    </CardBody>
                </Card>

            </div>
         );
    }
    else

/*                <div><br/></div>
                <Card>
                    <CardTitle>{item.name}</CardTitle>
                    <CardImg src={baseUrl + item.image} alt={item.name} />
                    <CardBody>
                    {item.date ? <CardSubtitle>{item.date}</CardSubtitle> : null }
                    <div className="row align-items-start">
                    <div>
                    {item.legend ? <CardImg src={baseUrl + item.legend} alt={item.legend} /> : null }
                    </div>
                    <div className="col-12 col-md m-1">
                    <CardText>{item.description}</CardText>
                    </div>
                    </div>
                    </CardBody>
                </Card>*/



    {
         return(
            <div>
                <p>server unavailable: {errMess}</p>
            </div>
        );
    }
}

function World(props) {
    return(
       <div>
                    <Jumbotron>
                    <div className="container">
                        <div className="header">
                            <div className="col-12 col-sm-6">
                                <h1>Statistics on the distribution and dynamics of COVID-19 in different countries on a map and curves</h1>
                                </div>
                        </div>
                    </div>
                    </Jumbotron>
        <Stagger in>
        <div className="container">
            <div className="row align-items-start">
                <div className="col-12 col-md m-1">
                <RenderCard item={props.article} 
                isLoading={props.articleLoading} 
                errMess={props.articleErrMess}  />
                </div>

                </div>
            </div>
            </Stagger>
        </div>
    );
}

export default World;