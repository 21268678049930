import React, { Component } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import Header from './HeaderComponent';
import Footer from './FooterComponent';
import World from './WorldComponent';
import Europe from './EuropeComponent';
import ListDetail from './ListdetailComponent';
import About from './AboutComponent';
import { connect } from 'react-redux';
import { fetchPromos } from '../redux/ActionCreators';
import { fetchCountry, fetchArticle } from '../redux/ActionCreators';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

const mapStateToProps = state => {
  return {
    promotions: state.promotions,
    article: state.article,
    country: state.country
  }
}

const mapDispatchToProps = dispatch => ({

  fetchPromos: () => { dispatch(fetchPromos())},
  fetchArticle: () => { dispatch(fetchArticle())},
  fetchCountry: () => { dispatch(fetchCountry())}

});

class Main extends Component {

  componentDidMount() {
    this.props.fetchPromos();
    this.props.fetchArticle();
    this.props.fetchCountry();
  }

  render() {

    const WorldPage = () => {
      return(
        <World 
        article={this.props.article.article.filter((article) => article.world)[0]}
        articleLoading={this.props.article.isLoading}
        articleErrMess={this.props.article.errMess}

       /> 

      );
    }

    const EuropePage = () => {
      return(
        <Europe 
        article={this.props.article.article.filter((article) => article.europe)[0]}
        articleLoading={this.props.article.isLoading}
        articleErrMess={this.props.article.errMess}

       /> 

      );
    }

    const ListdetailPage = () => {
      return(
        <ListDetail 
        article={this.props.article.article.filter((article) => article.world)[0]}
        article2={this.props.article.article.filter((article) => article.europe)[0]}
        articleLoading={this.props.article.isLoading}
        articleErrMess={this.props.article.errMess}
        
        //country={this.props.country.country.filter((country) => country.continent === "Europe")}
        country={this.props.country.country.filter((country) => country.id > -1)}
        //country={this.props.country.country.filter((country) => country.countryId === parseInt(match.params.countryId))}
        countryLoading={this.props.country.isLoading}
        countryErrMess={this.props.country.errMess}
           /> 

      );
    }

    const AboutPage = () => {
      return(
        <About

    /> 
   
      );
    }

    return (
      <div>
        <Header />
        <div>
        <TransitionGroup>
            <CSSTransition key={this.props.location.key} classNames="page" timeout={300}>
              <Switch location={this.props.location}>
                  <Route path='/world' component={WorldPage} />
                  <Route path='/europe' component={EuropePage} />
                  <Route path='/country' component={ListdetailPage} />
                  <Route exact path='/aboutus' component={AboutPage} />
                  <Redirect to="/world" />
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        </div>
        <Footer />
      </div>
    );
  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));