import React from 'react';
import { Breadcrumb, BreadcrumbItem, Card, CardBody, CardHeader, Media } from 'reactstrap';
import { Link } from 'react-router-dom';

function About(props) { //taisyti

    const leaders = props.leaders; //Ciakaip keisti ??? PROPSI KT?

        return (
        <div>
            <RenderHistory />

        </div>
        );
    


      function RenderHistory(){
      return(
        <div className="container">
            <div className="row">
                <Breadcrumb>
                    <BreadcrumbItem><Link to="/Europe">European map</Link></BreadcrumbItem>
                    <BreadcrumbItem active>About Us</BreadcrumbItem>
                </Breadcrumb>
                <div className="col-12">
                    <h3>About Us</h3>
                    <hr />
                </div>                
            </div>
            <div className="row row-content">
                <div className="col-12 col-md-6">
                    <strong>Privacy policy</strong>
                    <p>We do not collect any user data.</p>
                    <strong>Cookies</strong>
                    <p>We do not use any user cookies.</p>
                    <strong>accuracy of data</strong>
                    <p>We provide data and graphical information "as is" without obligation to ensure its accuracy</p>
                    <strong>Contact Us</strong>
                    <p>you can contact us by the email shown at the bottom of the page</p>
                    <strong>© Copyright 2020 covid.TreatUs.eu</strong>
                    <p>The use of images and articles is only permitted if the website logo is maintained or a link to the source is provided</p>


                </div>
                <div className="col-12 col-md-5">
                    <Card>
                        <CardHeader className="bg-primary text-white">Facts At a Glance</CardHeader>
                        <CardBody>
                            <dl className="row p-1">
                                <dt className="col-6">Status</dt>
                                <dd className="col-6">under development</dd>
                                <dt className="col-6">phase</dt>
                                <dd className="col-6">demo</dd>
                            </dl>
                        </CardBody>
                    </Card>
                </div>
                <div className="col-12">
                    <Card>
                        <CardBody className="bg-faded">
                            <blockquote className="blockquote">
                                <footer className="blockquote-footer">
                                <cite title="Source Title">This project is designed for learning purposes</cite>
                                </footer>
                            </blockquote>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    );
      }


}

export default About;    




